$(document).ready(function()
{
    /**
     * BODY CLASS HANDLING
     */
    $('body').placeholderFix().browserNotice().backToTop();

    // Toggle the "hovering" class on elements
    $('.tapHover').on('touchend', tapHover);

    /**
     * ACCORDION CALL
     */
    $('.accHandler').accordion({openFirst:true});

    /**
     * ESCAPE THE MAILMASK LINKS
     */
    $('a.escape').noSpam();

    /**
     * FORM VALIDATION AND SUBMIT VIA AJAX
     */
    $('form[data-validate="true"]').each(function()
    {
        $(this).validate();
    });

    /**
     * REPLACE a[href^="tel"] BY SPAN ELEMENT IF NOT MOBILE DEVICE
     */
    if(!body.className.match(/\bmobile_device\b/)) {
        phonelink();
    }

    $('.partner-slideshow').slick({
      dots: false,
      arrows: false,
      infinite: false,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 5000,
            speed: 3000,
            arrows: true
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 2000,
            speed: 2000,
            arrows: true
          }
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 2000,
            speed: 2000,
            arrows: true
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    });

    /**
     * CALL COLORBOX FOR IMAGES
     */
    $('a[data-rel^=lightbox]:not(.nolightbox)').lightcase({
        showCaption: false,
        labels: lightcaseLabels,
        maxWidth: $(window).width() * 0.8,
        maxHeight: $(window).height() * 0.8,
        shrinkFactor:1,
        type: 'image'
    });

    /**
     * CALL COLORBOX FOR EXTERNAL LINKS / YOUTUBE VIDEOS / ETC.
     */
    // $('a[data-rel^=lightbox-external]:not(.nolightbox)').lightcase({
    //     showCaption: false,
    //     labels: lightcaseLabels,
    //     type: 'iframe',
    //     maxWidth: $(window).width() * 0.8,
    //     maxHeight: $(window).height() * 0.8,
    //     iframe: {
    //         width: '600',
    //         height: $(window).height() * 0.8
    //     }
    // });

    /**
     * GET BREAKPOINTS FROM _CONFIG.SCSS FOR IF-STATEMENTS
     */
    if (breakpoint['medium']) {
        gM.init('.maps', 400);
    }

    if (breakpoint['large']) {
      var distance = $('.content').offset().top,
        $window = $(window);

      $window.scroll(function() {
        if ( $window.scrollTop() >= distance - 350 ) {
            $('body').addClass('shadow');
        } else {
          $('body').removeClass('shadow');
        }
      });
    }

    /**
     * detect external links and add target=_blank
     */
    $('a:external').attr('target', '_blank');

});
/////////////// END DOC READY

/**
* PLACE YOUR OWN SCRIPTS HERE
*/